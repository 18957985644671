<template>

<app-wrapper class="content">

	<i class="fa fa-spinner fa-spin content-loading" v-if="loading"></i>

	<template v-if="!loading">

		<slot></slot>

	</template>

</app-wrapper>

</template>

<script>

export default {

	props: ['loading']

}

</script>

<style scoped>

.content {
	flex-grow: 1;
}

.is-tablet .content {
	min-height: 200px;
}

.content-loading {
	position: absolute;
	left: 50%;
	top: calc(50% + 127px);
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #485CC7;
}

.is-tablet .content-loading {
	top: 50%;
}

</style>
